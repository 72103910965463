import { NavigationExtras } from '@angular/router';

export class ErrorRedirect extends Error {
  commands: any[] = [];
  extras?: NavigationExtras;

  constructor(message: string, commands: any[], extras?: NavigationExtras) {
    super(message);

    this.commands = commands;
    this.extras = extras;
  }
}
