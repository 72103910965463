import { ModuleWithProviders, NgModule } from '@angular/core';
import { UtilNavigationConfig } from './util-navigation.config';
import { NavigationService } from './navigation.service';

@NgModule({})
export class UtilNavigationModule {
  static forRoot(
    config: UtilNavigationConfig
  ): ModuleWithProviders<UtilNavigationModule> {
    return {
      ngModule: UtilNavigationModule,
      providers: [
        { provide: UtilNavigationConfig, useValue: config },
        NavigationService,
      ],
    };
  }
}
