import { Component, Input } from '@angular/core';

@Component({
  selector: 'h2m-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() menu = false;

  private _showMenu = false;

  get showMenu() {
    return this._showMenu;
  }

  toggle() {
    this._showMenu = !this.showMenu;
  }

  openMenu() {
    this._showMenu = true;
  }

  closeMenu() {
    this._showMenu = false;
  }
}
