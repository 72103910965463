import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Data,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilNavigationConfig } from './util-navigation.config';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  routeLoading$ = new Subject<boolean>();
  routeErrored$ = new Subject<NavigationError>();
  routeData$ = new BehaviorSubject<Data>({});

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private config: UtilNavigationConfig
  ) {
    this.router.events.subscribe((event) => {
      switch (event.constructor) {
        case NavigationStart:
          this.routeLoading$.next(true);
          break;
        case NavigationCancel:
          this.routeLoading$.next(false);
          break;
        case NavigationError:
          this.routeErrored$.next(event as NavigationError);
          this.routeLoading$.next(false);
          break;
        case NavigationEnd: {
          const data = this.getData();
          this.routeData$.next(data);
          this.setTitle(data);
          this.routeLoading$.next(false);
          break;
        }
      }
    });
  }

  updateTitle(title: string | string[]) {
    const titlePieces = [];

    titlePieces.push(...(Array.isArray(title) ? title : [title]));

    // Add the suffix
    titlePieces.push(this.config.titleSuffix);

    // Join by the separator and update the title
    this.titleService.setTitle(
      titlePieces.join(this.config.titleSeparator || '')
    );
  }

  /**
   * Piece together a title from the route data
   * @param data Route data
   */
  private setTitle(data: Data) {
    const title = data['title'];

    // Try to parse the title
    switch (typeof title) {
      case 'function':
        this.updateTitle(title(data));
        break;
      case 'string':
        this.updateTitle(title);
        break;
    }
  }

  /**
   * Find the data object for the current route
   * @returns Key value data structure
   */
  private getData(): Data {
    let child = this.route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data) {
        return child.snapshot.data;
      }
    }
    return {};
  }
}
