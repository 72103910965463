import { EnvironmentEnum } from '@sites/util-environment';

export const environment = {
  name: EnvironmentEnum.Development,
  production: false,
  domain: 'login-dev.humanmademachine.com',
  // Replaced by actual value during build
  release: '674270be7083d6cbfd23b583f715a86ddb4c8696',

  incubatorUrl: 'https://incubator-dev.humanmademachine.com',

  apiUrl: 'https://api-dev.humanmademachine.com',

  sentryDsn:
    'https://6504ab8504ec4403bbb00bfca4943b4c@o98546.ingest.sentry.io/6111916',
  sentrySampleRate: 1.0,
};
