import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { createGrpcWebTransport, Interceptor } from '@bufbuild/connect-web';
import { TRANSPORT } from './transport.token';
import { INTERCEPTORS } from './interceptor.token';
import { logInterceptor } from './log.interceptor';

@NgModule()
export class DataConnectModule {
  public static forRoot(
    grpcWebOptions?: Omit<
      Parameters<typeof createGrpcWebTransport>[0],
      'interceptors'
    >
  ): ModuleWithProviders<DataConnectModule> {
    return {
      ngModule: DataConnectModule,
      providers: grpcWebOptions ? this.getProviders(grpcWebOptions) : [],
    };
  }

  public static getProviders(
    grpcWebOptions: Omit<
      Parameters<typeof createGrpcWebTransport>[0],
      'interceptors'
    >
  ): Provider[] {
    const providers: Provider[] = [];

    providers.push({
      provide: TRANSPORT,
      useFactory: (interceptors: Interceptor[]) =>
        createGrpcWebTransport({
          ...grpcWebOptions,
          interceptors: interceptors,
        }),
      deps: [INTERCEPTORS],
    });

    try {
      if (window.localStorage.getItem('HMM_DEBUG')) {
        providers.push({
          provide: INTERCEPTORS,
          useValue: logInterceptor,
          multi: true,
        });
      }
    } catch (err) {
      console.error('could not get HMM_DEBUG state', err);
    }

    return providers;
  }
}
