<header>
  <div class="row align-items-center">
    <ng-content></ng-content>

    <div *ngIf="menu" class="col-auto">
      <button
        class="menu"
        [ngClass]="{ 'is-active': showMenu }"
        type="button"
        (click)="toggle()"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="menu-box">
          <span class="menu-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="row collapse menu-panel" [ngClass]="{ show: showMenu }">
    <div class="col">
      <ng-content select="[menu]"></ng-content>
    </div>
  </div>
</header>
