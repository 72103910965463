import { Pipe, PipeTransform } from '@angular/core';
import {
  ProductEnum,
  productLogos,
  productSmallLogos,
} from './product.constants';

@Pipe({ name: 'h2mProductLogo' })
export class ProductLogoPipe implements PipeTransform {
  transform(value: ProductEnum, small = false): string {
    return small ? productSmallLogos[value] : productLogos[value];
  }
}
