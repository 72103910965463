import { Component, Input } from '@angular/core';

@Component({
  selector: 'h2m-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  @Input() extended = false;
  @Input() logo = true;

  year = new Date().getFullYear();
}
