import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  createErrorHandler,
  captureException,
  SentryErrorHandler,
  setTag,
} from '@sentry/angular';
import { ErrorRedirect } from './error-redirect';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private sentryErrorHandler: SentryErrorHandler;
  private errors: any[] = [];

  constructor(private router: Router) {
    this.sentryErrorHandler = createErrorHandler({
      extractor: this.extractor.bind(this),
    });
  }

  /**
   * Handle all errors raised in the application
   * @param error
   */
  handleError(error: any) {
    // Send the error to Sentry for logging
    this.sentryErrorHandler.handleError(error);

    if (error instanceof ErrorRedirect) {
      this.router.navigate(error.commands, error.extras);
    }

    this.errors.push(error);
  }

  /**
   * Capture error in our logs
   * @param error
   */
  captureError(error: any) {
    // Send the error to Sentry
    captureException(error);

    this.errors.push(error);
  }

  /**
   * Try to find out what the last error message was
   * @returns Message
   */
  getLastMessage(): string {
    if (this.errors.length === 0) {
      return '';
    }

    return this.errors[this.errors.length - 1].message || 'Unknown error';
  }

  /**
   * Helper to tag all error events
   * @param name Name of the tag
   * @param value Value of the tag
   */
  addTag(name: string, value: string) {
    setTag(name, value);
  }

  /**
   * Extract our internal errors for better display on Sentry
   * @param error
   * @param defaultExtractor
   * @returns unknown
   */
  private extractor(
    error: unknown,
    defaultExtractor: (error: unknown) => unknown
  ) {
    // Use default extractor for everything else
    return defaultExtractor(error);
  }
}
