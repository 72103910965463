import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { InfoComponent } from './info/info.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { ModalComponent } from './modal/modal.component';
import { NgModule } from '@angular/core';
import { ProductLogoComponent } from './product/product-logo.component';
import { ProductLogoPipe } from './product/product-logo.pipe';
import { ProductNamePipe } from './product/product-name.pipe';
import { ProductUrlPipe } from './product/product-url.pipe';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ProgressComponent } from './progress/progress.component';
import { GhostItemComponent } from './ghost-item/ghost-item.component';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    BreadcrumbsComponent,
    FooterComponent,
    GhostItemComponent,
    HeaderComponent,
    InfoComponent,
    LoadingBarComponent,
    ModalComponent,
    ProductLogoComponent,
    ProductLogoPipe,
    ProductNamePipe,
    ProductUrlPipe,
    ProgressComponent,
    TooltipComponent,
    ThumbnailComponent,
  ],
  exports: [
    BreadcrumbsComponent,
    FooterComponent,
    GhostItemComponent,
    HeaderComponent,
    InfoComponent,
    LoadingBarComponent,
    ModalComponent,
    ProductLogoComponent,
    ProductLogoPipe,
    ProductNamePipe,
    ProductUrlPipe,
    ProgressComponent,
    TooltipComponent,
    ThumbnailComponent,
  ],
})
export class H2mModule {}
