<div class="container-fluid d-flex flex-column h-100 py-3">
  <h2m-header class="mb-5 text-center">
    <a href="https://www.humanmademachine.com" class="d-block">
      <h2m-product-logo></h2m-product-logo>
    </a>
  </h2m-header>
  <div class="flex-fill d-flex justify-content-center">
    <router-outlet></router-outlet>
  </div>
  <h2m-footer class="mt-5"></h2m-footer>
</div>
