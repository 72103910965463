import {
  Interceptor,
  UnaryResponse,
  UnaryRequest,
  ConnectError,
  StreamResponse,
} from '@bufbuild/connect-web';
import { ReadableStreamReadResultLike } from '@bufbuild/connect-web/dist/types/lib.dom.streams';
import { AnyMessage } from '@bufbuild/protobuf';

let requestId = 0;

export const logInterceptor: Interceptor = (next) => async (req) => {
  const start = Date.now();
  try {
    const res = await next(req);
    if (responseIsStream(res)) {
      logStreamResponse(start, req, res);
    } else {
      logUnaryResponse(start, req, res);
    }
    return res;
  } catch (err) {
    errorGroup(getLabel(start, req), [
      () => printRequest(req),
      () => printError(err),
    ]);
    throw err;
  }
};

function responseIsStream(
  res: UnaryResponse<AnyMessage> | StreamResponse<AnyMessage>
): res is StreamResponse<AnyMessage> {
  return res.stream === true;
}

function logUnaryResponse(
  start: number,
  req: UnaryRequest<AnyMessage>,
  res: UnaryResponse<AnyMessage>
) {
  group(getLabel(start, req), [
    () => printRequest(req),
    () => printResponse(res),
    () => printMessages(req, res),
  ]);
}

function logStreamResponse(
  start: number,
  req: UnaryRequest<AnyMessage>,
  res: StreamResponse<AnyMessage>
) {
  res.read().then((result) => {
    group(getLabel(start, req), [
      () => printRequest(req),
      () => printResponse(res),
      () => printStreamMessages(req, result),
    ]);

    if (!result.done) {
      logStreamResponse(start, req, res);
    }
  });
}

function group(name: string, actions: (() => void)[], style?: string) {
  console.groupCollapsed(`%c${name}`, style ? style : 'color: #666666;');
  actions.forEach((action) => action());
  console.groupEnd();
}

function getLabel(start: number, req: UnaryRequest<AnyMessage>): string {
  return `${++requestId}: ${Date.now() - start}ms - ${req.service.typeName} - ${
    req.method.name
  }`;
}

function errorGroup(name: string, actions: (() => void)[]) {
  group(name, actions, 'color: #f00505;');
}

function printRequest(req: UnaryRequest<AnyMessage>) {
  group('Request', [() => console.log(req)]);
}

function printResponse(
  res: UnaryResponse<AnyMessage> | StreamResponse<AnyMessage>
) {
  group('Response', [() => console.log(res)]);
}

function printMessages(
  req: UnaryRequest<AnyMessage>,
  res: UnaryResponse<AnyMessage>
) {
  group('Messages', [
    () => console.log('Request', JSON.stringify(req.message, null, 2)),
    () => console.log('Response', JSON.stringify(res.message, null, 2)),
  ]);
}

function printStreamMessages(
  req: UnaryRequest<AnyMessage>,
  res: ReadableStreamReadResultLike<AnyMessage>
) {
  group('Messages', [
    () => console.log('Request', JSON.stringify(req.message, null, 2)),
    () => console.log('Response', JSON.stringify(res, null, 2)),
  ]);
}

function printError(err: unknown) {
  group('Error', [
    () => console.log(err),
    () => (err instanceof ConnectError ? console.log({ ...err }) : ''),
  ]);
}
