<img
  src="{{ product | h2mProductLogo }}"
  class="normal"
  width="75px"
  height="75px"
  alt="{{ product | h2mProductName }}"
/>
<img
  src="{{ product | h2mProductLogo: true }}"
  class="small"
  width="45px"
  height="45px"
  alt="{{ product | h2mProductName }}"
/>
