import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { UtilErrorsModule } from '@sites/util-errors';
import { UtilNavigationModule } from '@sites/util-navigation';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { H2mModule } from '@sites/h2m';
import { DataConnectModule } from '@sites/data-connect';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataConnectModule.forRoot({
      baseUrl: environment.apiUrl,
    }),
    UtilNavigationModule.forRoot({
      titleSuffix: 'Login',
      titleSeparator: ' | ',
    }),
    UtilErrorsModule.forRoot(),
    H2mModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
