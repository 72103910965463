import { Component, Input } from '@angular/core';
import { ProductEnum } from '../product/product.constants';

@Component({
  selector: 'h2m-product-logo',
  templateUrl: './product-logo.component.html',
  styleUrls: ['./product-logo.component.scss'],
})
export class ProductLogoComponent {
  @Input() product: ProductEnum = ProductEnum.HumanMadeMachine;
}
