import { ErrorHandler as AngularErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandler implements AngularErrorHandler {
  constructor(private errorService: ErrorService) {}

  handleError(error: unknown): void {
    this.errorService.handleError(error);
  }
}
